import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import WiggleRightArrow from '../../shared/WiggleRightArrow';

import * as styles from './floatingCTA.module.scss';

const FloatingCTA = () => {
	const data = useStaticQuery(graphql`
		query FloatingCtaBlockQuery {
			allContentfulGenericBlock(
				filter: { type: { eq: "floating_cta_block" } }
			) {
				nodes {
					linkUrl
					linkText
				}
			}
		}
	`);

	return (
		<>
			<div className={styles.container}>
				<WiggleRightArrow
					{...data?.allContentfulGenericBlock?.nodes?.[0]}
					className={styles.capsule}
				/>
			</div>
		</>
	);
};

export default FloatingCTA;
