import * as React from 'react';
import classNames from 'classnames';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import WiggleMenu from '../WiggleMenu';
import * as styles from './nav.module.scss';

const NavLink = ({ to, children }) => (
	<Link to={to} className={styles.link} activeClassName={styles.active}>
		{children}
	</Link>
);

export default function Nav() {
	const data = useStaticQuery(graphql`
		query SiteQuery {
			contentfulMainNavigation {
				id
				pages {
					... on ContentfulLink {
						link
						newTab
						url
					}
					... on ContentfulPage {
						slug
					}
				}
			}
		}
	`);

	const [showMobile, setShowMobile] = React.useState(false);

	const pages = data.contentfulMainNavigation.pages.filter(
		page => page.slug !== '/'
	);

	const pageEls = pages.map(page => {
		return page.slug ? (
			<li key={page.slug || page.url}>
				<NavLink key={page.slug} to={`/${page.slug}`}>
					{page.slug}
				</NavLink>
			</li>
		) : (
			<li key={page.slug || page.url}>
				<a
					key={page.slug}
					className={styles.externalLink}
					href={page.url}
					target={page.newTab ? '_blank' : '_self'}
					rel="noreferrer"
				>
					{page.link}
				</a>
			</li>
		);
	});

	const HomeLink = (
		<li key="homeLink" className={styles.homeLink}>
			<NavLink to="/">Home</NavLink>
		</li>
	);
	const leftPages = pageEls.splice(0, Math.ceil(pages.length / 2));
	const rightPages = pageEls;

	const MobileNav = [HomeLink, ...leftPages, ...rightPages];

	const toggleNav = () => {
		setShowMobile(!showMobile);
	};

	return (
		<>
			<div className={styles.menuWrapper}>
				<Link to="/">
					<StaticImage
						src="../../../images/logo.png"
						alt="Logo for Brianna Fisher Photography & Videography"
						placeholder="none"
						loading="eager"
						objectFit="contain"
						width={64}
					/>
				</Link>
				<WiggleMenu clickFn={toggleNav} />
			</div>
			<nav className={styles.nav}>
				<ul>
					<li className={styles.logo}>
						<Link className="noTypo" to="/">
							<StaticImage
								src="../../../images/logo.png"
								alt="Logo for Brianna Fisher Photography & Videography"
								placeholder="none"
								loading="eager"
								objectFit="contain"
								width={128}
							/>
						</Link>
					</li>
					<span>{leftPages}</span>
					<span>{rightPages}</span>
				</ul>
			</nav>
			<ul
				className={classNames(styles.mobileNav, {
					[styles.showMobileNav]: showMobile
				})}
			>
				{MobileNav.map(el => (
					<li
						onClick={() => setShowMobile(false)}
						key={el.props.children.props.children}
						className={styles.mobileLinks}
						{...el.props}
					/>
				))}
			</ul>
		</>
	);
}
