import React from 'react';
import WiggleLink from '../WiggleLink/wiggleLink';
import MenuBars from '../MenuBars';
import * as styles from './wiggleMenu.module.scss';

const WiggleMenu = ({ clickFn }) => {
	return (
		<div className={styles.container}>
			<WiggleLink clickFn={clickFn}>
				<MenuBars />
			</WiggleLink>
		</div>
	);
};

WiggleMenu.defaultProps = {
	clickFn: () => {}
};

export default WiggleMenu;
