import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';

import Youtube from '@/icons/youtube.svg';
import Facebook from '@/icons/facebook.svg';
import Instagram from '@/icons/instagram.svg';
import Call from '@/icons/call.svg';
import Message from '@/icons/message.svg';

import * as styles from './footer.module.scss';

const NavLink = ({ to, children }) => <Link to={to}>{children}</Link>;

export default function Footer() {
	const data = useStaticQuery(graphql`
		query FooterQuery {
			contentfulFooter {
				id
				socialMediaTitle
				contactTitle
				email
				phone
				pages {
					... on ContentfulLink {
						link
						newTab
						url
					}
					... on ContentfulPage {
						slug
					}
				}
				socialMedia {
					link
					url
				}
			}
		}
	`);

	const { pages, socialMedia, socialMediaTitle, contactTitle, email, phone } =
		data.contentfulFooter;
	const acceptedMediaTypes = [
		{ type: 'Instagram', component: Instagram },
		{ type: 'Facebook', component: Facebook },
		{ type: 'Youtube', component: Youtube }
	];

	const formatPhone = num => {
		const phoneLink = num.replace(/\D/g, '');
		const phoneArr = num.split(/[-_.\s]+/);
		const phoneDisplay = `(${phoneArr[0]}) ${phoneArr[1]}-${phoneArr[2]}`;
		return {
			phoneLink,
			phoneDisplay
		};
	};

	const { phoneLink, phoneDisplay } = formatPhone(phone);

	const splitSocialTitle = str => {
		let arr = str.split(' ');
		let halve = arr.length / 2;
		let first = arr.slice(0, halve);
		let last = arr.slice(halve);
		return { first, last };
	};
	const { first, last } = splitSocialTitle(socialMediaTitle);

	const pageEls = pages.map(page => {
		return page.slug ? (
			<li key={page.slug || page.url}>
				<NavLink to={`/${page.slug === '/' ? '' : page.slug}`}>
					{page.slug === '/' ? 'home' : page.slug}
				</NavLink>
			</li>
		) : (
			<li key={page.slug || page.url}>
				<a
					href={page.url}
					target={page.newTab ? '_blank' : '_self'}
					rel="noreferrer"
				>
					{page.link}
				</a>
			</li>
		);
	});

	return (
		<footer className={styles.footer}>
			<address className={styles.address}>
				<section className={styles.social}>
					<h2 className="inline" aria-label={`${first} ${last}`}>
						<span aria-hidden className="bright">
							{first}
						</span>{' '}
						<span aria-hidden>{last}</span>
					</h2>
					<ul>
						{socialMedia.map(social => {
							let comp = acceptedMediaTypes.find(
								prop => prop.type === social.link
							);

							if (!comp?.type) return null;

							return (
								<li key={social.url} className={styles.socialLink}>
									{/* <a href={social.url}>{comp.component()}</a> */}
									<a href={social.url}>
										<comp.component />
									</a>
								</li>
							);
							// } else {
							// 	return (
							// 		<li key={social.url} className={styles.socialLink}>
							// 			<a href={social.url}>
							// 				<Youtube />
							// 			</a>
							// 		</li>
							// 	);
							// }
						})}
					</ul>
				</section>
				<section className={styles.contact}>
					<h2>{contactTitle}</h2>
					<a href={`tel:+${phoneLink}`}>
						<Call />
						{phoneDisplay}
					</a>
					<a href="mailto:contact@briannafisher.com">
						<Message />
						{email}
					</a>
				</section>
			</address>
			<nav className={styles.nav}>
				<ul>{pageEls}</ul>
			</nav>
		</footer>
	);
}
