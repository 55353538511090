import React from 'react';
import PropTypes from 'prop-types';
import random from 'lodash.random';
import classNames from 'classnames';

import * as styles from './wiggleLink.module.scss';
import ConditionalWrapper from '../ConditionalWrapper/conditionalWrapper';

function getRingPosMatrix() {
	return [...new Array(4)].map(() => `${random(-54, -46)}%`);
}

WiggleLink.propTypes = {
	resultsMsg: PropTypes.object,
	loading: PropTypes.bool,
	isSubmit: PropTypes.bool,
	linkUrl: PropTypes.string,
	linkText: PropTypes.string,
	externalLink: PropTypes.string,
	internalLink: PropTypes.shape({
		slug: PropTypes.string.isRequired,
		title: PropTypes.string
	}),
	className: PropTypes.any,
	clickFn: PropTypes.func
};

export default function WiggleLink({
	resultsMsg,
	linkUrl,
	linkText,
	externalLink,
	internalLink,
	className,
	children,
	clickFn,
	isSubmit,
	loading
}) {
	const [ringPosMatrix, setRingPosMatrix] = React.useState(getRingPosMatrix());
	React.useEffect(() => {
		setInterval(() => setRingPosMatrix(getRingPosMatrix()), 1500);
	}, []);

	linkUrl = linkUrl || externalLink || '/' + internalLink?.slug;
	linkText = linkText || internalLink?.title;

	return (
		<>
			<div className={classNames(styles.contentBlockLink, className)}>
				{linkText}
				<ConditionalWrapper
					condition={!linkUrl.includes('undefined') && linkUrl}
					linkWrap={children => <a href={linkUrl}>{children}</a>}
					btnWrap={children => {
						return (
							<button
								disabled={loading}
								type={isSubmit ? 'submit' : 'button'}
								onClick={clickFn}
							>
								{children}
							</button>
						);
					}}
				>
					<span
						className={styles.linkBall}
						style={{
							'--after-x': ringPosMatrix[0],
							'--after-y': ringPosMatrix[1],
							'--before-x': ringPosMatrix[2],
							'--before-y': ringPosMatrix[3]
						}}
					>
						{children}
					</span>
				</ConditionalWrapper>
			</div>
			<p
				className={classNames({
					[styles.success]: resultsMsg?.status === 'success',
					[styles.error]: resultsMsg?.status === 'error'
				})}
			>
				{resultsMsg?.message}
			</p>
		</>
	);
}
