import React from 'react';
import WiggleLink from '../WiggleLink/wiggleLink';
import ArrowRight from '@/icons/arrow-right.svg';
import * as styles from './styles.module.scss';

const WiggleRightArrow = props => {
	return (
		<WiggleLink {...props}>
			<ArrowRight stroke="white" strokeWidth="1" className={styles.arrow} />
		</WiggleLink>
	);
};

export default WiggleRightArrow;
