import * as React from 'react';

import '../styles/main.scss';

import Nav from '@/shared/Nav/nav';
import Footer from '@/shared/Footer/footer';
import FloatingCTA from '@/blocks/FloatingCTA';

const Layout = ({ children }) => {
	return (
		<>
			<div className="layoutGrid">
				<Nav />
				<main>{children}</main>
				<FloatingCTA />
				<Footer />
			</div>
		</>
	);
};

export default Layout;
